<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Сформированная педагогическая нагрузка</h4>



      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Язык</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="languageId">
            <option v-for="(sLanguage, sLanguageIndex) in [{id: 0, name:'Выберите язык'},...studyLanguages]"
                    :key="sLanguageIndex"
                    :value="sLanguage.id">
              {{ sLanguage.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Уровень обучения</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyLevelId">
            <option
                v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...docOrdersStudents_form.studyLevels]"
                :value="item.id"
                :key="index">{{ item.name }}
            </option>
          </select>

        </div>
      </div>

      <div class="mt-4 text-center" v-if="studyLevelId">


        <Button label="Вывести" @click="printDisciplines" style="margin-right: 5px"/>
        <Button label="Очистить" class="p-button-warning" @click="clear"/>

      </div>

    </div>
  </div>

  <div class="container-fluid">




    <!-- disciplines table -->
    <div class="mt-4 disciplines" v-if="ppsLoad_form.formedDisciplines.length != 0">


      <DataTable class="disciplines-list" :value="ppsLoad_form.formedDisciplines" :paginator="true" :rows="10" showGridlines
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 v-model:filters="filters"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                 responsiveLayout="scroll">

        <Column field="education_discipline_name" header="Название дисциплины"></Column>
        <!--          <Column field="language_name" header="Язык дисциплины">-->

        <!--            <template #filter="{filterModel}">-->
        <!--              <Dropdown v-model="filterModel.value" :options="studyLanguages" placeholder="Любой"-->
        <!--                        class="p-column-filter" :showClear="true">-->
        <!--                <template #value="slotProps">-->
        <!--                  <span v-if="slotProps.value">{{ slotProps.value }}</span>-->
        <!--                  <span v-else>{{ slotProps.placeholder }}</span>-->
        <!--                </template>-->
        <!--                <template #option="slotProps">-->
        <!--                  <span>{{ slotProps.option }}</span>-->
        <!--                </template>-->
        <!--              </Dropdown>-->
        <!--            </template>-->
        <!--          </Column>-->
        <Column header="ОП" style="width: 25%">
          <template #body="{data}">


            <div v-if="data.education_programs.length == 1">

              {{ data.education_programs[0].education_speciality_code }}
              {{ data.education_programs[0].education_speciality_name }} -
              {{ data.education_programs[0].admission_year }}

            </div>
            <div v-else>
              <p>

                <a data-bs-toggle="collapse" :href="'#collapseEpd'+data.education_discipline_id + data.semester + data.credit" aria-expanded="false"
                   :aria-controls="'collapseEpd'+data.education_discipline_id + data.semester + data.credit">
                  Список ОП
                </a>


              </p>
              <div class="collapse" :id="'collapseEpd'+data.education_discipline_id + data.semester + data.credit">

                <div v-for="(item, index) in data.education_programs" :key="index">
                  {{ item.education_speciality_code }} {{ item.education_speciality_name }} -
                  {{ item.admission_year }}
                  <br>
                  <br>
                </div>

              </div>
            </div>


          </template>
        </Column>
        <!--          <Column field="study_level_name" header="Уровень обучения"></Column>-->
        <Column header="Кред.">
          <template #body="{data}">
            <div v-if="data.is_standard == '1'">
              <b style="color: #a99c03">{{data.credit}}</b>
            </div>
            <div v-else>
              <b style="color: #2e9d00">{{data.credit}}</b>
            </div>
          </template>
        </Column>
        <Column field="semester" header="Сем.">
          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="semesters" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{slotProps.value}}</span>
                <span v-else>{{slotProps.placeholder}}</span>
              </template>
              <template #option="slotProps">
                <span>{{slotProps.option}}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column field="students" header="Кол. студ."></Column>
        <Column header="Лек. часы">
          <template #body="{data}">
            ×{{data.lecture_count}} ({{data.lecture_hours}})
          </template>
        </Column>
        <Column header="Сем. часы">
          <template #body="{data}">
            ×{{data.seminar_count}} ({{data.seminar_hours}})
          </template>
        </Column>
        <Column field="exam_hours" header="Экз."></Column>
        <Column header="Преподаватель">
          <template #body="{data}">

            <div v-if="data.pps_teaching_load.length == 0">
              <Button label="Выбрать" @click="openAddPpsLoadDialog(data.lecture_hours, data.lecture_count, data.seminar_hours,
                 data.seminar_count, data.exam_hours, data.students, data.education_discipline_id, data.education_discipline_name, data.semester, data.credit)"
                      style="margin-bottom: 5px"/>
            </div>
            <div v-else>
              <Button label="Обновить" class="p-button-secondary"
                      @click="openUpdatePpsLoadDialog(data.education_discipline_id, data.education_discipline_name, data.pps_teaching_load, data.pps_id)"
                      style="margin-bottom: 5px"/>
            </div>


          </template>
        </Column>

      </DataTable>
    </div>
    <!-- end disciplines table -->

  </div>


  <Dialog v-model:visible="addPpsLoadDisplay" style="width: 100%; max-width: 900px" :closable="false">
    <template #header>
      <h5>Добавление преподавателя к дисциплине {{ education_discipline_name }}</h5>
    </template>

    <div class="col-md-12 mt-4">

      <div class="lecture-hours" v-if="lecture_hours != 0">
        <h5>Лекционные часы: {{ lecture_hours }}</h5>
        <h5> Остаток количества студентов: {{ getLectureStudentsCount }}</h5>
        <div class="form-row" v-for="(n, index) in lecture_count" :key="index">


          <div class="form-group row mt-4">
            <label for="pps_id" class="col-md-3 col-form-label">Преподаватель {{ n }} ({{lectureForm[index]?.hour}})</label>
            <div class="col-md-9" id="pps_id">
              <select class="form-control form-select"
                      @input="changeLecture(index, 'pps_id', $event)">
                <!--                    <option selected>Выберите преподавателя</option>-->
                <option
                    v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                    :value="item.id"
                    :key="index">{{ item.lastname }} {{ item.firstname }}
                </option>
              </select>
            </div>

          </div>

          <!--              <div class="form-group row mt-4">-->
          <!--                <label class="col-md-3 col-form-label">Количество часов</label>-->
          <!--                <div class="col-md-9">-->

          <!--                  <input type="number" class="form-control"-->
          <!--                         placeholder="Количество часов"-->
          <!--                         :value="lectureForm[index]?.hour" @input="changeLecture(index,'hour', $event)">-->
          <!--                </div>-->
          <!--              </div>-->

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Количество студентов</label>
            <div class="col-md-9">

              <input type="number" class="form-control"
                     placeholder="Количество студентов"
                     :value="lectureForm[index]?.students_count"
                     @input="changeLecture(index,'students_count', $event)">
            </div>
          </div>

          <hr>


        </div>
        <div class="mt-4 add-row-lecture-hours">

          <div class="form-row" v-for="(lecturePpsTeachingLoadInfo, lecturePpsTeachingLoadInfoIndex) in ppsLoad_form.newLecturePpsTeachingLoad"
               :key="lecturePpsTeachingLoadInfoIndex">

            <div class="remove-item__button d-flex flex-row-reverse">
              <i class="fa fa-times" @click="deleteRowLecture(lecturePpsTeachingLoadInfoIndex)"></i>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Преподаватель ({{ lectureForm[0].hour }})</label>
              <div class="col-md-9">
                <select class="form-control form-select"
                        @input="changeLecturePpsTeachingLoadData(lecturePpsTeachingLoadInfo,'pps_id', $event)">
                  <option v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                          :value="item.id"
                          :key="index">{{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>

            <!--                <div class="form-group row mt-4">-->
            <!--                  <label class="col-md-3 col-form-label">Количество часов</label>-->
            <!--                  <div class="col-md-9">-->

            <!--                    <input type="number" class="form-control"-->
            <!--                           placeholder="Количество часов"-->
            <!--                           @input="changeLecturePpsTeachingLoadData(lecturePpsTeachingLoadInfo,'hour', $event)">-->
            <!--                  </div>-->
            <!--                </div>-->

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Количество студентов</label>
              <div class="col-md-9">

                <input type="number" class="form-control"
                       placeholder="Количество студентов"
                       @input="changeLecturePpsTeachingLoadData(lecturePpsTeachingLoadInfo,'students_count', $event)">
              </div>
            </div>
            <hr>


          </div>

        </div>
        <Button v-if="getLectureStudentsCount != 0" icon="pi pi-plus" @click="addRowLecture(lectureForm[0].hour)"/>

      </div>


      <div class="mt-4 seminar-hours" v-if="seminar_hours != 0">
        <h5>Семинарские часы: {{ seminar_hours }} </h5>
        <h5>Остаток количества студентов: {{ getSeminarStudentsCount }}</h5>
        <div class="form-row" v-for="(n, index) in seminar_count" :key="index">


          <div class="form-group row mt-4">
            <label for="seminar_pps_id" class="col-md-3 col-form-label">Преподаватель {{ n }} ({{seminarForm[index]?.hour}})</label>
            <div class="col-md-9" id="seminar_pps_id">
              <select class="form-control form-select"
                      @input="changeSeminar(index, 'pps_id', $event)">
                <!--                    <option selected>Выберите преподавателя</option>-->
                <option
                    v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                    :value="item.id"
                    :key="index">{{ item.lastname }} {{ item.firstname }}
                </option>
              </select>
            </div>

          </div>

          <!--              <div class="form-group row mt-4">-->
          <!--                <label class="col-md-3 col-form-label">Количество часов</label>-->
          <!--                <div class="col-md-9">-->

          <!--                  <input type="number" class="form-control"-->
          <!--                         placeholder="Количество часов"-->
          <!--                         :value="seminarForm[index]?.hour" @input="changeSeminar(index,'hour', $event)">-->
          <!--                </div>-->
          <!--              </div>-->

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Количество студентов</label>
            <div class="col-md-9">

              <input type="number" class="form-control"
                     placeholder="Количество студентов"
                     :value="seminarForm[index]?.students_count"
                     @input="changeSeminar(index,'students_count', $event)">
            </div>
          </div>

          <hr>


        </div>


        <div class="mt-4 add-row-seminar-hours">

          <div class="form-row" v-for="(seminarPpsTeachingLoadInfo, seminarPpsTeachingLoadInfoIndex) in ppsLoad_form.newSeminarPpsTeachingLoad"
               :key="seminarPpsTeachingLoadInfoIndex">

            <div class="remove-item__button d-flex flex-row-reverse">
              <i class="fa fa-times" @click="deleteRowSeminar(seminarPpsTeachingLoadInfoIndex)"></i>
            </div>

            <div class="form-group row mt-4">
              <label for="pps_id" class="col-md-3 col-form-label">Преподаватель ({{seminarForm[0].hour}})</label>
              <div class="col-md-9">
                <select class="form-control form-select"
                        @input="changeSeminarPpsTeachingLoadData(seminarPpsTeachingLoadInfo,'pps_id', $event)">
                  <option v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                          :value="item.id"
                          :key="index">{{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>

            <!--                            <div class="form-group row mt-4">-->
            <!--                              <label class="col-md-3 col-form-label">Количество часов</label>-->
            <!--                              <div class="col-md-9">-->

            <!--                                <input type="number" class="form-control"-->
            <!--                                       placeholder="Количество часов"-->
            <!--                                       @input="changeSeminarPpsTeachingLoadData(seminarPpsTeachingLoadInfo,'hour', $event)">-->
            <!--                              </div>-->
            <!--                            </div>-->

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Количество студентов</label>
              <div class="col-md-9">

                <input type="number" class="form-control"
                       placeholder="Количество студентов"
                       @input="changeSeminarPpsTeachingLoadData(seminarPpsTeachingLoadInfo,'students_count', $event)">
              </div>
            </div>
            <hr>


          </div>

        </div>

        <Button v-if="getSeminarStudentsCount != 0" icon="pi pi-plus" @click="addRowSeminar(seminarForm[0].hour)"/>

      </div>


      <div class="mt-4 exam-hours">
        <h5>Экзаменационные часы: {{ exam_hours }}</h5>
        <div class="form-row" v-for="(n, index) in seminar_count" :key="index">

          <div class="form-group row mt-4">
            <label for="exam_pps_id" class="col-md-3 col-form-label">Преподаватель {{ n }} ({{examForm[index]?.hour}})</label>
            <div class="col-md-9" id="exam_pps_id">
              <select class="form-control form-select"
                      @input="changeExam(index, 'pps_id', $event)">
                <!--                    <option selected>Выберите преподавателя</option>-->
                <!--                    [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]-->
                <option
                    v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                    :value="item.id"
                    :key="index">{{ item.lastname }} {{ item.firstname }}
                </option>
              </select>
            </div>

          </div>

          <!--              <div class="form-group row mt-4">-->
          <!--                <label class="col-md-3 col-form-label">Количество часов</label>-->
          <!--                <div class="col-md-9">-->

          <!--                  <input type="number" class="form-control"-->
          <!--                         placeholder="Количество часов"-->
          <!--                         :value="examForm[index]?.hour" @input="changeExam(index,'hour', $event)">-->
          <!--                </div>-->
          <!--              </div>-->

          <hr>

        </div>

      </div>

    </div>

    <template #footer>
      <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeAddPpsLoadDialog"/>
      <Button label="Сохранить" icon="pi pi-check" @click="submitPpsTeachingLoad"/>
    </template>
  </Dialog>


  <Dialog v-model:visible="updatePpsLoadDisplay" :style="{width: '50vw'}" :closable="false">
    <template #header>
      <h5>Обновление нагрузки дисциплины {{ update_education_discipline_name }}</h5>
    </template>

    <div class="col-md-12 mt-4">

      <div class="mt-4" v-for="(educationType, educationTypeIndex) in ppsLoad_form.educationType"
           :key="educationTypeIndex">
        <h4>{{ educationType.name }}</h4>

        <div v-for="(ppsLoad, ppsLoadIndex) in ppsTeachingLoadUpdate.filter(i=>i.education_type_id == educationType.id)"
             :key="ppsLoadIndex">
          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Преподаватель</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeUpdatePpsTeachingLoad(ppsLoad.id, 'pps_id', $event)">
                <option v-for="(item, index) in  [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                        :value="item.id"
                        :selected="item.id == ppsLoad.pps_id"
                        :key="index">{{ item.lastname }} {{ item.firstname }}
                </option>
              </select>
            </div>

          </div>


          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Количество часов</label>
            <div class="col-md-9">

              <input type="number" class="form-control"
                     placeholder="Количество часов"
                     :value="ppsLoad?.hour"
                     @input="changeUpdatePpsTeachingLoad(ppsLoad.id,'hour', $event)"
                     disabled>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Количество студентов</label>
            <div class="col-md-9">

              <input type="number" class="form-control"
                     placeholder="Количество студентов"
                     :value="ppsLoad?.students_count"
                     @input="changeUpdatePpsTeachingLoad(ppsLoad.id,'students_count', $event)"
                     disabled>
            </div>
          </div>



        </div>

      </div>


    </div>

    <template #footer>
      <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeUpdatePpsLoadDialog"/>
      <Button label="Обновить" icon="pi pi-check" @click="updatePpsTeachingLoad"/>
    </template>
  </Dialog>



</template>


<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex"

export default {
  name: "FormedTeachingLoad",
  data() {
    return {
      loading: true,
      studyLevelId: 0,
      studyCourse: 0,
      languageId: 0,
      filters: {

        'language_name': {value: null, matchMode: FilterMatchMode.EQUALS},
        'semester': {value: null, matchMode: FilterMatchMode.EQUALS},

      },
      studyLanguages: [
        {
          id: 137,
          name: 'русский язык'
        },
        {
          id: 82,
          name: 'қазақ тілі',
        },
        {
          id: 39,
          name: 'English'
        }],

      studyCourses: [
        {
          value: 2,
          name: '2'
        },
        {
          value: 3,
          name: '3'
        },
        {
          value: 4,
          name: '4'
        },
      ],
      semesters: [3 , 4 , 5 , 6, 7, 8],
      addPpsLoadDisplay: false,
      updatePpsLoadDisplay: false,
      lecture_hours: 0,
      seminar_hours: 0,
      lecture_count: 0,
      seminar_count: 0,
      exam_hours: 0,
      exam_count: 0,
      education_discipline_name: '',
      update_education_discipline_name: '',
      lectureForm: [],
      seminarForm: [],
      examForm: [],
      students_count: 0,
      ppsTeachingLoad: [],
      ppsTeachingLoadUpdate: [],
      semester: 0,
      credit: 0
    }
  },
  computed: {
    ...mapState('ppsLoad', ['ppsLoad_form']),
    ...mapState('docOrdersStudents', ['docOrdersStudents_form']),
    ...mapGetters('ppsLoad', ['getRowLectureStudentsCount', 'getRowSeminarStudentsCount']),
    getLectureStudentsCount() {
      return this.students_count - parseInt((this.lectureForm.reduce((sum, item) => +item.students_count + sum, 0))) - parseInt(this.getRowLectureStudentsCount)
    },
    getSeminarStudentsCount() {
      return this.students_count - parseInt((this.seminarForm.reduce((sum, item) => +item.students_count + sum, 0))) - parseInt(this.getRowSeminarStudentsCount)
    },


  },
  methods: {
    ...mapActions('ppsLoad', ['GET_FORMED_DISCIPLINES', 'GET_TEACHERS', 'POST_PPS_TEACHING_LOAD', 'POST_DISCIPLINE_VACANCY',
      'GET_EDUCATION_TYPE', 'PUT_PPS_TEACHING_LOAD']),
    ...mapMutations('ppsLoad', ['CLEAR_DISCIPLINES', 'SET_NEW_LECTURE_PPS_TEACHING_LOAD_DATA',
      'ADD_ROW_LECTURE', 'DELETE_ROW_LECTURE', 'SET_NEW_SEMINAR_PPS_TEACHING_LOAD_DATA',
      'SET_EDUCATION_DISCIPLINE_ID', 'ADD_ROW_SEMINAR', 'DELETE_ROW_SEMINAR', 'CLEAR_STATE',
      'SET_UPDATE_PPS_TEACHING_LOAD', 'CLEAR_UPDATE_PPS_TEACHING_LOAD']),
    ...mapActions('docOrdersStudents', ['GET_STUDY_LEVELS']),
    isLecturePpsSelected() {
      if (this.lectureForm[0].pps_id === 0) {
        return false
      }
      else {
        return true
      }
    },
    isSeminarPpsSelected() {
      if (this.seminarForm[0].pps_id === 0) {
        return false
      }
      else {
        return true
      }
    },
    isExamPpsSelected() {
      if (this.examForm[0].pps_id === 0) {
        return false
      }
      else {
        return true
      }
    },
    getLectureHours() {
      // if (this.ppsLoad_form.newLecturePpsTeachingLoad.length == 0) {
      //   return this.lecture_hours
      // }
      // else {
      //   return parseInt(this.lecture_hours) - parseInt((this.ppsLoad_form.newLecturePpsTeachingLoad.reduce((sum, item) => +item.lecture_hour + sum, 0)))
      // }

      if (this.lectureForm[0].pps_id == 0 || this.lectureForm[0].lecture_hour == 0) {
        return this.lecture_hours
      } else {
        return parseInt(this.lecture_hours) - parseInt((this.lectureForm.reduce((sum, item) => +item.lecture_hour + sum, 0)))
      }

    },

    getSeminarHours() {
      // if (this.ppsLoad_form.newSeminarPpsTeachingLoad.length == 0) {
      //   return this.seminar_hours
      // }
      // else {
      //   return parseInt(this.seminar_hours) - parseInt((this.ppsLoad_form.newSeminarPpsTeachingLoad.reduce((sum, item) => +item.seminar_hour + sum, 0)))
      // }
      if (this.seminarForm[0].pps_id == 0 || this.seminarForm[0].seminar_hour == 0) {
        return this.seminar_hours
      } else {
        return parseInt(this.seminar_hours) - parseInt((this.seminarForm.reduce((sum, item) => +item.seminar_hour + sum, 0)))
      }

    },
    async printDisciplines() {
      await this.GET_FORMED_DISCIPLINES({languageId: this.languageId, studyLevelId: this.studyLevelId})
    },
    clear() {
      this.studyCourse = 0
      this.studyLevelId = 0
      this.CLEAR_DISCIPLINES()
    },
    openAddPpsLoadDialog(lecture_hours, lecture_count, seminar_hours, seminar_count, exam_hours, students, education_discipline_id, education_discipline_name, semester, credit) {
      this.addPpsLoadDisplay = true
      this.lecture_hours = lecture_hours
      this.lecture_count = lecture_count
      this.seminar_hours = seminar_hours
      this.seminar_count = seminar_count
      this.exam_hours = exam_hours
      this.exam_count = seminar_count
      this.education_discipline_name = education_discipline_name
      this.semester = semester
      this.credit = credit

      this.students_count = students
      console.log(this.students_count)


      let lecture_students_count = Math.floor(this.students_count / lecture_count)
      let seminar_students_count = Math.floor(this.students_count / seminar_count)

      const groupsSeminar = []
      for (let i = 0; i < seminar_count; i++) {
        groupsSeminar.push(seminar_students_count)
      }

      let seminarMod = this.students_count % seminar_count

      for (let k = 0; k < seminarMod; k++) {
        groupsSeminar[k] += 1
      }

      const groupsLecture = []
      for (let i = 0; i < lecture_count; i++) {
        groupsLecture.push(lecture_students_count)
      }

      let lectureMod = this.students_count % lecture_count

      for (let k = 0; k < lectureMod; k++) {
        groupsLecture[k] += 1
      }


      for (let i = 0; i < this.lecture_count; i++) {
        this.lectureForm.push({
          pps_id: null,
          hour: lecture_hours / lecture_count,
          students_count: groupsLecture[i],
          education_discipline_id: education_discipline_id,
          education_type_id: 1,
          semester: this.semester,
          credit: this.credit
        })
      }
      console.log(this.lectureForm, 'lectureForm')


      for (let i = 0; i < this.seminar_count; i++) {


        this.seminarForm.push({
          pps_id: null,
          hour: seminar_hours / seminar_count,
          students_count: groupsSeminar[i],
          education_discipline_id: education_discipline_id,
          education_type_id: 2,
          semester: this.semester,
          credit: this.credit
        })

      }
      console.log(this.seminarForm, 'seminarForm')


      for (let i = 0; i < this.exam_count; i++) {
        this.examForm.push({
          pps_id: null,
          hour: exam_hours / this.exam_count,
          education_discipline_id: education_discipline_id,
          education_type_id: 3,
          semester: this.semester,
          credit: this.credit
        })
      }
      console.log(this.examForm, 'examForm')


      this.SET_EDUCATION_DISCIPLINE_ID(education_discipline_id)
      this.GET_TEACHERS(education_discipline_id)
    },
    closeAddPpsLoadDialog() {
      this.addPpsLoadDisplay = false
      this.lectureForm = []
      this.seminarForm = []
      this.examForm = []
      this.lecture_hours = 0
      this.lecture_count = 0
      this.seminar_hours = 0
      this.seminar_count = 0
      this.exam_hours = 0
      this.exam_count = 0
      this.semester = 0
      this.credit = 0
      this.CLEAR_STATE()
    },


    openUpdatePpsLoadDialog(education_discipline_id, update_education_discipline_name, ppsTeachingLoad, pps_id) {
      this.updatePpsLoadDisplay = true
      this.ppsTeachingLoadUpdate = ppsTeachingLoad
      this.update_education_discipline_name = update_education_discipline_name
      //this.SET_UPDATE_EDUCATION_DISCIPLINE_ID(education_discipline_id)
      //this.SET_UPDATE_PPS_ID(pps_id)


      this.GET_TEACHERS(education_discipline_id)
    },
    closeUpdatePpsLoadDialog() {
      this.updatePpsLoadDisplay = false
      this.update_education_discipline_name = ''
      this.education_discipline_id = 0
      this.ppsTeachingLoadUpdate = []
      this.CLEAR_UPDATE_PPS_TEACHING_LOAD()

    },


    changeLecturePpsTeachingLoadData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.SET_NEW_LECTURE_PPS_TEACHING_LOAD_DATA({item, property, value})
    },
    changeSeminarPpsTeachingLoadData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.SET_NEW_SEMINAR_PPS_TEACHING_LOAD_DATA({item, property, value})
    },
    changeLecture(index, property, e, val = 'value') {
      this.lectureForm[index][property] = e.target[val]
      console.log(this.lectureForm, 'lectureForm')
    },
    changeSeminar(index, property, e, val = 'value') {
      this.seminarForm[index][property] = e.target[val]
      console.log(this.seminarForm, 'seminarForm')
    },
    changeExam(index, property, e, val = 'value') {
      this.examForm[index][property] = e.target[val]
      console.log(this.examForm, 'examForm')
    },
    addRowLecture(hour) {
      this.ADD_ROW_LECTURE(hour);
    },
    addRowSeminar(hour) {
      this.ADD_ROW_SEMINAR(hour);
    },
    changeUpdatePpsTeachingLoad(id, property, e, val = 'value') {
      const value = e.target[val]
      this.SET_UPDATE_PPS_TEACHING_LOAD({id, property, value})
    },
    async deleteRowLecture(index) {
      // let deleteId = this.ppsLoad_form.newPpsTeachingLoad[index].id
      //
      // if(deleteId) {
      //   await this.DELETE_PPS_TEACHING_LOAD(deleteId);
      //   await this.GET_DISCIPLINES()
      // }

      await this.DELETE_ROW_LECTURE(index);
    },

    async deleteRowSeminar(index) {
      // let deleteId = this.ppsLoad_form.newPpsTeachingLoad[index].id
      //
      // if(deleteId) {
      //   await this.DELETE_PPS_TEACHING_LOAD(deleteId);
      //   await this.GET_DISCIPLINES()
      // }

      await this.DELETE_ROW_SEMINAR(index);
    },

    async submitPpsTeachingLoad() {
      this.addPpsLoadDisplay = false
      let data = await this.POST_PPS_TEACHING_LOAD({
        lectureForm: this.lectureForm,
        seminarForm: this.seminarForm,
        examForm: this.examForm
      })
      if (data) {

        await this.POST_DISCIPLINE_VACANCY()
        this.lectureForm = []
        this.seminarForm = []
        this.examForm = []
        this.lecture_hours = 0
        this.lecture_count = 0
        this.seminar_hours = 0
        this.seminar_count = 0
        this.exam_hours = 0
        this.exam_count = 0
        this.education_discipline_name = ''
        this.semester = 0
        this.credit = 0
        this.CLEAR_STATE()
        await this.GET_DISCIPLINES({languageId: this.languageId, studyLevelId: this.studyLevelId})
        this.$message({text: 'Преподаватели привязаны'})
      }

    },
    async updatePpsTeachingLoad() {
      let data = await this.PUT_PPS_TEACHING_LOAD()
      if (data) {
        this.updatePpsLoadDisplay = false
        this.CLEAR_STATE()
        await this.GET_DISCIPLINES({languageId: this.languageId, studyLevelId: this.studyLevelId})
        this.$message({text: 'Нагрузка обновлена'})
      }
    },

    async submitDisciplineVacancy() {
      let data = await this.POST_DISCIPLINE_VACANCY()
      if (data) {
        this.addPpsLoadDisplay = false
        await this.GET_DISCIPLINES({languageId: this.languageId, studyLevelId: this.studyLevelId})
        this.$message({text: 'Вакансия отправлена'})
      }
    }
  },
  async mounted() {
    this.loading = false
    await this.CLEAR_DISCIPLINES()
    await this.GET_STUDY_LEVELS()
    await this.GET_EDUCATION_TYPE()
  }
}
</script>

<style scoped>
.disciplines-list.p-datatable .p-datatable-thead>tr>th {
  width: 100px!important;
}
</style>